import './BenefitsAnimations.scss';

import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect, useRef, useState } from 'react';

import animation1280 from 'assets/animation-1280.mp4';
import animation320 from 'assets/animation-320.mp4';
import animation480 from 'assets/animation-480.mp4';
import animation640 from 'assets/animation-640.mp4';
import animation960 from 'assets/animation-960.mp4';
import nerdspace from 'assets/projects/nerdspace-1600.webp';
import Card from 'components/Card';
import VideoCrop from 'components/VideoCrop';

const usePlayOnEnter = (ref: React.RefObject<HTMLDivElement>) => {
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    const element = ref.current!;

    ScrollTrigger.create({
      trigger: element,
      start: 'bottom bottom',
      onEnter: () => setCanPlay(true),
    });
  }, []);

  return canPlay;
};

const Anim = () => {
  const ref = useRef<HTMLDivElement>(null);
  const canPlay = usePlayOnEnter(ref);

  return (
    <div ref={ref} className="anim-video-wrap">
      <div className="anim-video-frame">
        <VideoCrop
          srcSet={{
            1280: animation1280,
            960: animation960,
            640: animation640,
            480: animation480,
            320: animation320,
          }}
          width={1280}
          height={720}
          canPlay={canPlay}
          poster={nerdspace}
        />
      </div>
    </div>
  );
};

const BenefitsAnimations = () => (
  <Card
    className="benefits-animations"
    cardClassName="intro-grid-cell--animation"
  >
    <div>
      <h3 className="intro-card-heading">
        세련된
        <br />
        애니메이션
      </h3>
      <p className="intro-card-description">
        UX를 개선시킬 수 있는 미묘한 인터랙션이나
        <br className="lg-br" />{' '}
        &quot;와우&quot; 감탄사가 절로 나오는 복잡한 애니매이션 등
        <br className="lg-br" />
        모든 작업 가능합니다.
      </p>
    </div>
    <Anim />
  </Card>
);

export default BenefitsAnimations;
