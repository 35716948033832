import './Hero.scss';

import gsap from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { Swiper as SwiperRef } from 'swiper';
import { Autoplay, Controller, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as Android } from 'assets/icons/android-color.svg';
import { ReactComponent as Apple } from 'assets/icons/apple.svg';
import { ReactComponent as React } from 'assets/icons/react.svg';
import Image from 'components/Image';

const Hero = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [controlledSwiper, setControlledSwiper] = useState<SwiperRef>();
  const tlRef = useRef<gsap.core.Timeline>();

  useEffect(() => {
    controlledSwiper?.zoom.in();

    const onResize = () => {
      const windowWidth = window.innerWidth;

      tlRef.current?.kill();

      if (windowWidth <= 991) {
        return;
      }

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ref.current,
          start: '3.5% top',
          end: '90% bottom',
          scrub: true,
        },
        defaults: {
          duration: 1,
        },
      });
      tl.fromTo('.hero-ui-vid-wrap', { x: 0 }, { x: '-130%' });
      tl.fromTo('.hero-ui-window', { x: '130%' }, { x: 0 }, '<=');
      tl.fromTo(
        '.hero',
        { scale: 0.36 },
        { scale: Math.min(0.85 + (1 - windowWidth / 1728), 1.125), y: 40 },
      );
      tl.fromTo(
        '.hero-ui-window img, .hero-img-ui',
        { opacity: 1 },
        { opacity: 0 },
        '<=',
      );
      tl.to('.hero-ui-window', { boxShadow: 'none' }, '<=');
      tl.fromTo(
        '.desc h3',
        { y: 'calc(-35% + 50px)', opacity: 0 },
        {
          y: '-35%',
          opacity: 1,
          duration: 0.5,
        },
      );
      tl.fromTo(
        '.desc h3',
        {
          backgroundPosition: '100% 0%',
        },
        { backgroundPosition: '0% 0%' },
      );

      tlRef.current = tl;
    };

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const onSlideChangeTransitionStart = () => {
    controlledSwiper?.zoom.out();
  };

  const onSlideChangeTransitionEnd = () => {
    controlledSwiper?.zoom.in();
  };

  return (
    <>
      <div className="hero-scroll-wrap">
        <div ref={ref} className="hero-scroll-trigger" />
        <div className="sticky-wrap">
          <div className="overflow-wrap">
            <div className="hero">
              <div className="hero-ui">
                <Image
                  className="hero-img-ui"
                  path="hero"
                  name="full-bg"
                  width={2580}
                  height={1482}
                  keys={[300, 550, 800, 1200, 1600, 2000, 2580]}
                />
                <div className="hero-ui-inner">
                  <div className="hero-ui-vid-wrap">
                    <Swiper
                      onSwiper={setControlledSwiper}
                      autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                      }}
                      loop
                      speed={1500}
                      modules={[Autoplay, Zoom, Controller]}
                      zoom={{
                        maxRatio: 1.2,
                        minRatio: 1,
                        zoomedSlideClass: 'img-wrap',
                      }}
                      allowTouchMove={false}
                      onSlideChangeTransitionStart={
                        onSlideChangeTransitionStart
                      }
                      onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
                    >
                      {[1, 2, 3].map((i) => (
                        <SwiperSlide key={i}>
                          <div className="swiper-zoom-container">
                            <div className="img-wrap">
                              <Image
                                path="hero"
                                name={`kmong_${i}`}
                                width={1600}
                                height={837}
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="hero-ui-window">
                    <Image
                      path="hero"
                      name="kmong_empty"
                      width={1600}
                      height={837}
                    />
                    <div className="wrapper">
                      <div className="inner">
                        <ul>
                          <li>
                            <Android />
                          </li>
                          <li>
                            <Apple />
                          </li>
                          <li>
                            <React />
                          </li>
                        </ul>
                        <h2 className="gradient-heading">
                          인터랙티브
                          <br />
                          디벨로퍼
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="desc page-padding">
              <div className="container">
                <h3 className="kmong-expert">
                  IT·프로그래밍 크몽
                  <br />
                  <span>
                    Top2% <i>Prime</i> 전문가
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container subtitle-small">
        <h3 className="kmong-expert">
          IT·프로그래밍 크몽
          <br />
          <span>
            Top2% <i>Prime</i> 전문가
          </span>
        </h3>
      </div>
    </>
  );
};

export default Hero;
