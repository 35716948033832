import './Nav.scss';

import { RefObject } from 'react';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { kmongLink } from '../utils/utils';
import HoverLink from './HoverLink';

interface Props {
  benefitsRef: RefObject<HTMLElement>;
  projectsRef: RefObject<HTMLElement>;
  processRef: RefObject<HTMLElement>;
  faqRef: RefObject<HTMLElement>;
}

const Nav = ({ benefitsRef, projectsRef, processRef, faqRef }: Props) => {
  const scrollToTop = () =>
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  const scrollTo = (ref: RefObject<HTMLElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <nav className="nav">
      <div className="page-padding">
        <div className="container">
          <div className="nav-inner">
            <HoverLink fakeLink className="logo" onClick={scrollToTop}>
              <span>{'인터랙티브\n디벨로퍼'}</span>
            </HoverLink>
            <ul className="nav-list">
              <li>
                <HoverLink fakeLink onClick={() => scrollTo(projectsRef)}>
                  프로젝트
                </HoverLink>
              </li>
              <li>
                <HoverLink fakeLink onClick={() => scrollTo(benefitsRef)}>
                  제공 서비스
                </HoverLink>
              </li>
              <li>
                <HoverLink fakeLink onClick={() => scrollTo(processRef)}>
                  작업 과정
                </HoverLink>
              </li>
              <li>
                <HoverLink fakeLink onClick={() => scrollTo(faqRef)}>
                  자주 묻는 질문
                </HoverLink>
              </li>
            </ul>
            <HoverLink
              className="kmong-link"
              href={kmongLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              크몽 전문가
              <ArrowIcon />
            </HoverLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
