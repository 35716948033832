import './VideoCrop.scss';

import { memo, useEffect, useRef } from 'react';

import useSize from 'hooks/useSize';

interface Props {
  srcSet: { [key: number]: string };
  width: number;
  height: number;
  canPlay: boolean;
  poster?: string;
}

const ImageCrop: React.FC<Props> = ({
  srcSet,
  width,
  height,
  canPlay,
  poster,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [containerWidth] = useSize(ref);

  useEffect(() => {
    const video = videoRef.current!;

    if (canPlay) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise.then((_) => {}).catch((error) => {});
      }
    } else {
      video.pause();
      setTimeout(() => {
        video.currentTime = 0;
      }, 800);
    }
  }, [canPlay]);

  const getSrc = () => {
    const minSize =
      (window.devicePixelRatio * window.innerWidth) /
      (window.innerWidth > 768 ? 1 : 2);

    const keys = Object.keys(srcSet);
    let key = keys[keys.length - 1];
    for (let i = keys.length - 2; i >= 0; i--) {
      if (+keys[i] < minSize) {
        break;
      } else {
        key = keys[i];
      }
    }
    return srcSet[+key];
  };

  return (
    <div ref={ref} className="video-crop">
      <video
        ref={videoRef}
        loop
        muted
        width={width}
        height={height}
        playsInline
        poster={poster}
      >
        <source src={getSrc()}></source>
      </video>
    </div>
  );
};

export default memo(ImageCrop);
