import './Projects.scss';

import { gsap, Power3 } from 'gsap';
import Flip from 'gsap/Flip';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { forwardRef, useEffect, useRef, useState } from 'react';

import { ReactComponent as LoadPlusIcon } from 'assets/icons/loadplus.svg';
import HoverLink from 'components/HoverLink';
import useSize from 'hooks/useSize';
import ProjectDetails from '../project-details/ProjectDetails';
import ProjectItem from './components/ProjectItem';

const projects = [
  {
    url: 'https://nerdspace.interactivedeveloper.co.kr/',
    name: 'nerdspace',
    title: 'Nerd Space',
    startColor: '#5dcff9',
    endColor: '#974db8',
    year: '2022',
    keywords: 'React, Gsap, TypeScript',
  },
  {
    url: 'https://wizlive.interactivedeveloper.co.kr/',
    name: 'wizlive',
    title: '위즈라이브',
    startColor: '#c29e88',
    endColor: '#afcadb',
    year: '2022',
    keywords: 'React, Gsap, TypeScript',
  },
  {
    url: 'https://clothit.interactivedeveloper.co.kr/',
    name: 'clothit',
    title: 'Cloth it',
    startColor: '#3a3c47',
    endColor: '#6b7077',
    year: '2022',
    keywords: 'React, Gsap, TypeScript',
  },
  {
    url: 'https://nukim.interactivedeveloper.co.kr/',
    name: 'nukim',
    title: '느낌',
    startColor: '#f4ba24',
    endColor: '#E6870F',
    year: '2023',
    keywords: 'Wix, WebGL',
  },
  {
    url: 'https://r-clip.interactivedeveloper.co.kr/',
    name: 'rclip',
    title: 'R-clip',
    startColor: '#4f1f1c',
    endColor: '#da7b7a',
    year: '2023',
    keywords: 'Next.js, TypeScript, Firebase',
  },
  {
    url: 'https://pickcar.interactivedeveloper.co.kr/',
    name: 'pickcar',
    title: '차픽',
    startColor: '#b1b1b1',
    endColor: '#e3e3e3',
    year: '2022',
    keywords: 'React, Gatsby, Gsap, TypeScript, Tailwind',
  },
  {
    url: 'https://yalliyalli.interactivedeveloper.co.kr/',
    name: 'yalliyalli',
    title: 'Yalli Yalli',
    startColor: '#98cac4',
    endColor: '#c1ffe1',
    year: '2022',
    keywords: 'Vue.js',
  },
  {
    url: 'https://moneybox-theta.vercel.app/',
    name: 'moneybox',
    title: 'MoneyBox',
    startColor: '#f6905d',
    endColor: '#00a5b5',
    year: '2022',
    keywords: 'Next.js, TypeScript, i18n, Firebase',
  },
];

const projectsMobile = [
  {
    appleUrl: 'https://apps.apple.com/kr/app/id6476815215',
    googleUrl: 'https://play.google.com/store/apps/details?id=kr.co.kimseungri',
    name: 'vicleague',
    title: 'VIC LEAGUE',
    year: '2024',
    keywords: 'React Native, Node.js, Express, MySQL, TypeScript',
  },
  {
    appleUrl: 'https://apps.apple.com/kr/app/id6470256218',
    googleUrl: 'https://play.google.com/store/apps/details?id=kr.co.daon',
    name: 'daon',
    title: '다온',
    year: '2023',
    keywords: 'React Native, Node.js, Express, AWS, MySQL, TypeScript',
  },
  {
    appleUrl: 'https://apps.apple.com/kr/app/id1658243191',
    googleUrl: 'https://play.google.com/store/apps/details?id=com.pickcar',
    name: 'pickcar-mobile',
    title: '차픽',
    year: '2022',
    keywords: 'React Native, Node.js, Express, AWS, MySQL, TypeScript',
  },
  {
    appleUrl: 'https://apps.apple.com/kr/app/id1670783176',
    googleUrl: 'https://play.google.com/store/apps/details?id=com.cdbio',
    name: 'cdbio',
    title: 'CDBio',
    year: '2023',
    keywords: 'React Native, TypeScript, i18n',
  },
  {
    appleUrl: 'https://apps.apple.com/kr/app/id6450511684',
    googleUrl: 'https://play.google.com/store/apps/details?id=kr.co.linki',
    name: 'linki',
    title: '링크아이',
    year: '2023',
    keywords: 'React Native, Node.js, Express, AWS, MySQL, TypeScript',
  },
  {
    appleUrl: 'https://apps.apple.com/kr/app/id6468365207',
    googleUrl: 'https://play.google.com/store/apps/details?id=com.ucando.nurse',
    name: 'ucando',
    title: '유캔두널스',
    year: '2023',
    keywords: 'React Native, Node.js, Express, AWS, MySQL, TypeScript',
  },
  {
    appleUrl: 'https://apps.apple.com/kr/id6449602730',
    googleUrl:
      'https://play.google.com/store/apps/details?id=com.capsulecloset',
    name: 'capsulecloset',
    title: '캡슐 옷장',
    year: '2023',
    keywords: 'React Native, Node.js, Express, AWS, MySQL, TypeScript',
  },
  // {
  //   appleUrl: 'https://apps.apple.com/kr/app/id6464587979',
  //   googleUrl: 'https://play.google.com/store/apps/details?id=com.greatbee',
  //   name: 'greatbee',
  //   title: '그레이트비',
  //   year: '2023',
  //   keywords: 'React Native, Node.js, Express, AWS, MySQL, TypeScript',
  // },
  {
    appleUrl: 'https://apps.apple.com/kr/app/id1661247112',
    googleUrl: 'https://play.google.com/store/apps/details?id=com.fromx',
    name: 'fromx',
    title: 'From.X',
    year: '2023',
    keywords: 'React Native, Node.js, Express, AWS, MySQL, TypeScript',
  },
  {
    appleUrl: 'https://apps.apple.com/kr/app/id6450552452',
    googleUrl: 'https://play.google.com/store/apps/details?id=com.nugucar',
    name: 'nugucar',
    title: '누구카',
    year: '2023',
    keywords: 'React Native, TypeScript, OCR',
  },
  {
    appleUrl: 'https://apps.apple.com/kr/app/id1636427395',
    googleUrl:
      'https://play.google.com/store/apps/details?id=kr.co.keypair.Metastone',
    name: 'metastone',
    title: 'MetaStone',
    year: '2022',
    keywords: 'React Native, TypeScript',
  },
  {
    name: 'doctorigin',
    title: '닥터리진',
    year: '2022',
    keywords: 'React Native, TypeScript, Firebase, Cafe24',
  },
  {
    name: 'briphy',
    title: '브리피',
    year: '2023',
    keywords: 'React Native, TypeScript',
  },
];

const Projects = forwardRef<HTMLElement>((_, ref) => {
  const [activeItem, setActiveItem] = useState<
    | {
        ref: HTMLAnchorElement;
        name: string;
        url: string;
        title: string;
        year: string;
      }
    | {
        ref: HTMLAnchorElement;
        name: string;
        appleUrl?: string;
        googleUrl?: string;
        title: string;
        year: string;
      }
    | null
  >(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [windowWidth] = useSize();
  const [numberSlide, setNumberSlide] = useState(4);
  const [numberMobileSlide, setNumberMobileSlide] = useState(3);

  const showDetails = (
    item:
      | {
          ref: HTMLAnchorElement;
          name: string;
          url: string;
          title: string;
          year: string;
        }
      | {
          ref: HTMLAnchorElement;
          name: string;
          appleUrl?: string;
          googleUrl?: string;
          title: string;
          year: string;
        },
  ) => {
    setIsModalOpen(true);
    setTimeout(() => setActiveItem(item), 0);
  };

  useEffect(() => {
    if (activeItem) {
      const details = modalRef.current!;
      const detailImage = details.querySelector(
        '.project-details-img-preview',
      )!;

      const onLoad = () => {
        // position the details on top of the item (scaled down)
        Flip.fit(
          '.overlay-inner',
          activeItem.ref.querySelector('.img-inner >div:last-child'),
          {
            scale: true,
            fitChild: detailImage,
          },
        );

        // record the state
        const state = Flip.getState(details.querySelector('.overlay-inner'));

        // set the final state
        gsap.set(details.querySelector('.overlay-inner'), { clearProps: true }); // wipe out all inline stuff so it's in the native state (not scaled)
        gsap.set(details.querySelector('.overlay-inner'), {
          visibility: 'visible',
          overflow: 'auto',
        });

        Flip.from(state, {
          duration: 0.5,
          scale: true,
          ease: Power3.easeInOut,
        });

        gsap.set(document.body, { overflow: 'hidden' });
        gsap.set(details.querySelector('.project-item-info-wrap'), {
          height: 'auto',
        });

        if (windowWidth <= 991) {
          gsap.from(details.querySelector('.project-details'), {
            borderRadius: 0,
            duration: 0.5,
          });
        }
        gsap.from(details.querySelector('.project-item-info-wrap'), {
          height: 0,
          duration: 0.5,
        });
        gsap.to(details.querySelector('.close-btn'), {
          rotate: 45,
          duration: 0.5,
        });

        gsap.to(details, {
          overflow: 'auto',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          backdropFilter: 'blur(px)',
          duration: 0.2,
          delay: 0.3,
        });
      };

      setTimeout(onLoad, 100);
    }
  }, [activeItem]);

  const hideDetails = () => {
    setIsModalOpen(false);

    const details = modalRef.current!;
    const detailImage = details.querySelector('.project-details-img-preview')!;

    gsap.set(details, { overflow: 'hidden' });
    // record the current state of details
    const state = Flip.getState(details.querySelector('.overlay-inner'));
    // scale details down so that its detailImage fits exactly on top of activeItem
    Flip.fit(
      details.querySelector('.overlay-inner'),
      activeItem!.ref.querySelector('.img-inner >div:last-child'),
      {
        scale: true,
        fitChild: detailImage,
      },
    );

    gsap.to(details, {
      overflow: 'hidden',
      backgroundColor: 'unset',
      backdropFilter: 'unset',
      duration: 0.2,
    });

    gsap.to(details.querySelector('.project-item-info-wrap'), {
      height: 0,
      duration: 0.5,
    });

    if (windowWidth <= 991) {
      gsap.to(details.querySelector('.project-details'), {
        borderRadius: 16,
        duration: 0.5,
      });
    }

    gsap.to(details.querySelector('.close-btn'), {
      rotate: 0,
      duration: 0.5,
    });

    // animate from the original state to the current one.
    Flip.from(state, {
      scale: true,
      duration: 0.5,
      ease: Power3.easeInOut,
      onComplete: () => {
        gsap.set(document.body, { overflow: 'auto' });
        setActiveItem(null);
      },
    }).set(details, { visibility: 'hidden' });
  };

  const onLoadMore = () => {
    setNumberSlide((i) => i + 2);
    setTimeout(() => ScrollTrigger.refresh(), 0);
  };

  const onLoadMoreMobile = () => {
    setNumberMobileSlide((i) => i + 3);
    setTimeout(() => ScrollTrigger.refresh(), 0);
  };

  return (
    <>
      <section ref={ref} className="section section--light projects-sec">
        <div className="page-padding">
          <div className="container">
            <div className="section-header">
              <h2 className="section-heading">
                최상의 프로젝트
                <br />
                맛보기
              </h2>
            </div>
            <div className="container container-large">
              <h3>웹사이트</h3>
              <div className="projects-wrap">
                <ul className="projects-list">
                  {projects.slice(0, numberSlide).map((project) => (
                    <ProjectItem
                      key={project.name}
                      {...project}
                      active={activeItem?.name === project.name}
                      onClick={showDetails}
                    />
                  ))}
                </ul>
                {numberSlide < projects.length && (
                  <HoverLink
                    fakeLink
                    className="load-more-button"
                    onClick={onLoadMore}
                    resetOnClick
                  >
                    <span>더 보기</span>
                    <div className="load-more-plus">
                      <LoadPlusIcon />
                    </div>
                  </HoverLink>
                )}
              </div>
            </div>
            <div className="container container-large projects-mobile">
              <h3>모바일 앱</h3>
              <div className="projects-wrap">
                <ul className="projects-list">
                  {projectsMobile.slice(0, numberMobileSlide).map((project) => (
                    <ProjectItem
                      key={project.name}
                      {...project}
                      active={activeItem?.name === project.name}
                      onClick={showDetails}
                      isMobile
                    />
                  ))}
                </ul>
                {numberMobileSlide < projectsMobile.length && (
                  <HoverLink
                    fakeLink
                    className="load-more-button"
                    onClick={onLoadMoreMobile}
                    resetOnClick
                  >
                    <span>더 보기</span>
                    <div className="load-more-plus">
                      <LoadPlusIcon />
                    </div>
                  </HoverLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectDetails
        isOpen={isModalOpen}
        onReject={hideDetails}
        {...activeItem}
        ref={modalRef}
      />
    </>
  );
});

Projects.displayName = 'Projects';

export default Projects;
