import './Card.scss';

import React, { forwardRef, memo, MouseEvent, useRef } from 'react';
import cx from 'classnames';
import mergeRefs from 'react-merge-refs';
import gsap from 'gsap';

interface Props {
  children: React.ReactNode;
  className?: string;
  cardClassName?: string;
}

const Card = forwardRef<HTMLDivElement, Props>(
  ({ children, className, cardClassName }, ref) => {
    const cardRef = useRef<HTMLDivElement>(null);

    const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
      const element = cardRef.current!;
      const rect = e.currentTarget.getBoundingClientRect();

      const posX = e.clientX - rect.left;
      const posY = e.clientY - rect.top;

      gsap.to(element.querySelector('.card-highlight'), {
        backgroundImage: `radial-gradient(400px at ${posX}px ${posY}px, rgba(255, 255, 255, 0.4), transparent)`,
      });
      gsap.to(element.querySelector('.card-bg-border'), {
        backgroundImage: `radial-gradient(480px at ${posX}px ${posY}px, rgba(255, 255, 255, 0.5), transparent 40%)`,
      });
    };

    return (
      <div
        ref={mergeRefs([ref, cardRef])}
        onMouseMove={onMouseMove}
        className={cx('card-wrap', className)}
      >
        <div className={cx('card', cardClassName)}>{children}</div>
        <div className="card-bg">
          <div className="card-bg-border" />
        </div>
        <div className="card-highlight" />
      </div>
    );
  },
);

export default memo(Card);
