import gsap from 'gsap';
import { HTMLProps, MouseEvent, useRef } from 'react';

import useSize from 'hooks/useSize';
import FakeLink from './FakeLink';

interface Props extends HTMLProps<HTMLAnchorElement> {
  fakeLink?: boolean;
  resetOnClick?: boolean;
}

const HoverLink = ({
  fakeLink,
  className,
  onClick = () => {},
  resetOnClick,
  ...rest
}: Props) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const [windowWidth] = useSize();

  const mouseEnter = () => {
    if (windowWidth < 992) {
      return;
    }

    document.querySelector('.cursor')!.classList.add('is-locked');
    gsap.set(ref.current, {
      scale: 1.025,
    });
    gsap.set('.cursor', {
      width: ref.current!.clientWidth,
      height: ref.current!.clientHeight,
    });
  };

  const mouseLeave = () => {
    if (windowWidth < 992) {
      return;
    }

    document.querySelector('.cursor')!.classList.remove('is-locked');
    gsap.set(ref.current, {
      scale: 1,
      x: 0,
      y: 0,
    });
    gsap.set('.cursor', {
      width: 20,
      height: 20,
    });
  };

  const mouseMove = (e: MouseEvent<HTMLAnchorElement>) => {
    if (windowWidth < 992) {
      return;
    }

    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const cusorSet = gsap.quickSetter('.cursor', 'css');

    const horizontalToCenter = (x / ref.current!.clientWidth) * 2 - 1;
    const verticalToCenter = (y / ref.current!.clientHeight) * 2 - 1;

    cusorSet({
      top: e.clientY - (verticalToCenter * ref.current!.clientHeight) / 2,
      left: e.clientX - (horizontalToCenter * ref.current!.clientWidth) / 2,
      x: horizontalToCenter * 3,
      y: verticalToCenter * 0.1 * 1.4,
    });

    const refSet = gsap.quickSetter(ref.current, 'css');
    refSet({
      x: horizontalToCenter * 12,
      y: verticalToCenter * 3,
    });
  };

  const click = (e: MouseEvent<HTMLAnchorElement>) => {
    onClick(e);
    if (windowWidth < 992) {
      return;
    }
    if (resetOnClick) {
      mouseLeave();
    }
  };

  if (fakeLink) {
    return (
      <FakeLink
        className={className}
        {...rest}
        ref={ref}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onMouseMove={mouseMove}
        onClick={click}
      />
    );
  }

  return (
    <a
      className={className as string}
      {...rest}
      ref={ref}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onMouseMove={mouseMove}
      onClick={click}
    />
  );
};

export default HoverLink;
