import './Home.scss';

import Hero from 'sections/hero/Hero';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as AndroidIcon } from 'assets/icons/android.svg';
import { ReactComponent as AppleIcon } from 'assets/icons/apple.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as AwsIcon } from 'assets/icons/aws.svg';
import { ReactComponent as HTML5Icon } from 'assets/icons/html5.svg';
import { ReactComponent as NextJSIcon } from 'assets/icons/nextjs.svg';
import { ReactComponent as NodeJSIcon } from 'assets/icons/nodejs.svg';
import { ReactComponent as ReactIcon } from 'assets/icons/react.svg';
import { ReactComponent as TypeScriptIcon } from 'assets/icons/typescript.svg';
import HoverLink from 'components/HoverLink';
import { kmongLink } from 'utils/utils';

const Home = () => {
  return (
    <section className="home-sec">
      <div className="page-padding">
        <div className="container">
          <div className="home-hero-header">
            <div className="home-hero-heading-wrap">
              <h1 className="home-hero-heading">
                탁월한 앱과 <br />
                웹사이트 개발
              </h1>
            </div>
            <p className="home-hero-subheading">
              새로운 기술과 트렌드를 끊임없이 추구하며,
              <br />
              다양한 고객들의 요구에 부응하는
              <br className="sm-br" />
              최상의 개발을 약속합니다
            </p>
            <HoverLink
              className="kmong-link"
              href={kmongLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              크몽에서 저를 찾아주세요
              <ArrowIcon />
            </HoverLink>
          </div>
          <div className="home-hero-logos">
            <div className="home-hero-logos-overlay" />
            <Swiper
              slidesPerView={5}
              spaceBetween={30}
              freeMode={true}
              autoplay={{
                delay: 1,
                disableOnInteraction: false,
              }}
              speed={3000}
              loop
              modules={[Autoplay, FreeMode]}
              className="swiper"
              allowTouchMove={false}
            >
              <SwiperSlide>
                <span className="icon-wrap">
                  <span className="text-wrap">mobile</span>
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="icon-wrap">
                  <AppleIcon />
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="icon-wrap">
                  <ReactIcon />
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="icon-wrap">
                  <AndroidIcon />
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="icon-wrap">
                  <NodeJSIcon />
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="icon-wrap">
                  <span className="text-wrap">web</span>
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="icon-wrap">
                  <TypeScriptIcon />
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="icon-wrap">
                  <NextJSIcon />
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="icon-wrap">
                  <HTML5Icon />
                </span>
              </SwiperSlide>
              <SwiperSlide>
                <span className="icon-wrap">
                  <AwsIcon />
                </span>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <Hero />
    </section>
  );
};

export default Home;
