import './Image.scss';

import cx from 'classnames';
import { HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLImageElement> {
  name: string;
  path?: string;
  extension?: string;
  keys?: number[] | null;
  className?: string;
  alt?: string;
  width: number;
  height: number;
  crossOrigin?: '' | 'anonymous' | 'use-credentials';
}

const Image = ({
  name,
  path = '',
  extension = 'webp',
  keys = [300, 550, 800, 1200, 1600],
  className,
  alt = '',
  width,
  height,
  draggable = false,
  ...rest
}: Props) => {
  const getImage = (size: number | null): string =>
    new URL(
      `${import.meta.env.DEV ? '../': '/'}assets${path ? '/' + path : ''}/${name}${
        size ? `-${size}` : ''
      }.${extension}`,
      import.meta.url,
    ).href;

  const srcSet = keys
    ? keys.map((key) => `${getImage(key)} ${key}w`).join(', ')
    : getImage(null);

  return (
    <div className={cx('img-fallback', className)}>
      <picture
        style={{
          paddingTop: `${(height / width) * 100}%`,
        }}
      >
        <source
          data-srcset={srcSet}
          type={`image/${extension}`}
        />
        <img
          src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          className="lazyload lazypreload"
          data-src={getImage(keys ? keys[keys.length - 1] : null)}
          alt={alt}
          width={width}
          height={height}
          draggable={draggable}
          {...rest}
        />
      </picture>
    </div>
  );
};

export default Image;
