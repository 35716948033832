import Card from 'components/Card';

const BenefitsIntro = () => (
  <Card>
    <h3 className="intro-card-heading">
      크몽 모바일 앱 및 웹<br />
      풀스택 개발
    </h3>
    <p className="intro-card-description">
      제공해 주신 어떤 디자인이라도 디테일을 살리고 우아한 애니메이션을 활용하여
      정확한 픽셀로 완성도 있는 웹사이트와 모바일 앱을 만들어 드립니다. 디자인
      감각이 없는 개발자와의 답답한 작업 진행은 잊으셔도 좋습니다.
      <br />
      프로젝트에 사용하고자 하시는 특정 테크가 있다면 어떤 기술이라도 적용해
      드릴 수 있으며 해당 프로젝트에 가장 적합한 기술을 제안해 드릴수도
      있습니다.
    </p>
  </Card>
);

export default BenefitsIntro;
