import './index.scss';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/zoom';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import 'lazysizes';

import { getAnalytics } from 'firebase/analytics';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { gsap } from 'gsap';
import Flip from 'gsap/Flip';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactModal from 'react-modal';
import { polyfill } from 'seamless-scroll-polyfill';

import App from './App';

polyfill();

ReactModal.setAppElement('#root');

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Flip);
gsap.config({
  force3D: true,
  nullTargetWarn: import.meta.env.DEV,
});

const firebaseConfig: FirebaseOptions = JSON.parse(
  import.meta.env.VITE_API_KEY || process.env.REACT_APP_API_KEY || '{}',
);

initializeApp(firebaseConfig);
if (process.env.NODE_ENV !== 'development') {
  getAnalytics();
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
