import './Contact.scss';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import HoverLink from 'components/HoverLink';
import { kmongLink } from 'utils/utils';
import BottomBg from 'assets/bottom-bg.webp';

const Contact = () => {
  return (
    <>
      <div className="section section--footer">
        <div className="page-padding">
          <div className="container">
            <div className="card card-footer-cta">
              <img
                className="footer-cta-bg"
                src={BottomBg}
                alt=""
                loading="lazy"
              />
              <div className="footer-cta-content">
                <h2 className="section-heading">
                  함께 작업하기를
                  <br />
                  원하시나요?
                </h2>
                <HoverLink
                  href={kmongLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  크몽에서 저를 찾아주세요
                  <ArrowIcon />
                </HoverLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="page-padding">
          <div className="container">
            <p className="copyright">
              © 2023 Interactive Developer. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Contact;
