import { forwardRef, HTMLProps, MouseEvent } from 'react';

const FakeLink = forwardRef<HTMLAnchorElement, HTMLProps<HTMLAnchorElement>>(
  ({ className = null, onClick = () => {}, children = null, ...rest }, ref) => {
    const onFakeLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onClick(e);
    };

    return (
      <a
        ref={ref}
        href="#"
        className={className as string}
        onClick={onFakeLinkClick}
        {...rest}
      >
        {children}
      </a>
    );
  },
);

FakeLink.displayName = 'FakeLink';

export default FakeLink;
