import Card from 'components/Card';
import Responsive from 'assets/responsive-development.webp';

const BenefitsResponsive = () => {
  return (
    <Card className="benefits-responsive" cardClassName="card--screen-slice">
      <div className="screen-slice-content">
        <h3 className="intro-card-heading">
          반응형 <br />
          개발
        </h3>
        <p className="intro-card-description intro-card-description--animations">
          앱과 모바일에 최적화된 반응형 개발을 해 드립니다.
        </p>
      </div>
      <img
        src={Responsive}
        alt="mobile-app"
        className="screen-slice-img-left"
        width={996}
        height={1005}
      />
    </Card>
  );
};

export default BenefitsResponsive;
