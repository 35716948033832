import './Benefits.scss';

import { forwardRef, useEffect, useRef } from 'react';

import BenefitsAnimations from './components/BenefitsAnimations';
import BenefitsCleanCode from './components/BenefitsCleanCode';
import BenefitsFast from './components/BenefitsFast';
import BenefitsIntro from './components/BenefitsIntro';
import BenefitsStats from './components/BenefitsStats';
import BenefitsPerformance from './components/BenefitsPerformance';
import BenefitsResponsive from './components/BenefitsResponsive';
import ScrollTrigger from 'gsap/ScrollTrigger';
import mergeRefs from 'react-merge-refs';

const Benefits = forwardRef<HTMLElement>((_, ref) => {
  const triggerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const setDark = () => {
      document.querySelector('.nav')!.classList.add('dark');
    };

    const setLight = () => {
      document.querySelector('.nav')!.classList.remove('dark');
    };

    ScrollTrigger.create({
      trigger: triggerRef.current!,
      start: '-1px top',
      onEnter: setDark,
      onEnterBack: setDark,
      onLeave: setLight,
      onLeaveBack: setLight,
    });
  }, []);

  return (
    <section
      ref={mergeRefs([ref, triggerRef])}
      className="section section--benefits"
    >
      <div className="benefits-bg-blur"/>
      <div className="page-padding">
        <div className="container">
          <div className="section-header">
            <h3 className="section-heading">
              원하시는 제품
              <br />
              맞춤 개발
            </h3>
          </div>
          <div className="cards-grid">
            <BenefitsStats />
            <BenefitsIntro />
            <BenefitsAnimations />
            <BenefitsCleanCode />
            <BenefitsFast />
            <BenefitsResponsive />
            <BenefitsPerformance />
          </div>
        </div>
      </div>
    </section>
  );
});

Benefits.displayName = 'Benefits';

export default Benefits;
