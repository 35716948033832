import './App.scss';

import { useRef } from 'react';
import Benefits from 'sections/benefits/Benefits';
import Contact from 'sections/contact/Contact';
import Faq from 'sections/faq/Faq';
import Home from 'sections/home/Home';
import Process from 'sections/process/Process';
import Projects from 'sections/projects/Projects';

import Cursor from 'components/Cursor';
import Nav from 'components/Nav';
import useScrollToTop from 'hooks/useScrollToTop';
import Reviews from 'sections/reviews/Reviews';

function App() {
  const benefitsRef = useRef<HTMLElement>(null);
  const projectsRef = useRef<HTMLElement>(null);
  const processRef = useRef<HTMLElement>(null);
  const faqRef = useRef<HTMLElement>(null);

  useScrollToTop();

  return (
    <>
      <Cursor />
      <Nav
        benefitsRef={benefitsRef}
        projectsRef={projectsRef}
        processRef={processRef}
        faqRef={faqRef}
      />
      <Home />
      <Projects ref={projectsRef} />
      <Reviews />
      <Benefits ref={benefitsRef} />
      <Process ref={processRef} />
      <Faq ref={faqRef} />
      <Contact />
    </>
  );
}

export default App;
