import './ProjectItem.scss';

import cx from 'classnames';
import { gsap } from 'gsap';
import {
  forwardRef,
  HTMLProps,
  memo,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import mergeRefs from 'react-merge-refs';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import FakeLink from 'components/FakeLink';
import Image from 'components/Image';
import useSize from 'hooks/useSize';

const RotateItem = memo(
  forwardRef<HTMLAnchorElement, HTMLProps<HTMLAnchorElement>>((props, ref) => {
    const [windowWidth] = useSize();

    const elementRef = useRef<HTMLAnchorElement>(null);
    const tlRef = useRef<gsap.core.Tween>();

    const rotationXSetRef = useRef<Function>();
    const rotationYSetRef = useRef<Function>();

    useEffect(() => {
      const element = elementRef.current!;
      rotationXSetRef.current = gsap.quickSetter(element, 'rotationX', 'deg');
      rotationYSetRef.current = gsap.quickSetter(element, 'rotationY', 'deg');
    }, []);

    const onMouseMove = (e: MouseEvent<HTMLAnchorElement>) => {
      const element = elementRef.current!;
      const { clientWidth, clientHeight } = element;
      const rect = e.currentTarget.getBoundingClientRect();

      const posX = e.clientX - rect.left - clientWidth / 2;
      const posY = -(e.clientY - rect.top - clientHeight / 2);

      const rotationX = -(7.8 * posY) / (clientHeight / 2);
      const rotationY = -(7.8 * posX) / (clientWidth / 2);

      rotationXSetRef.current!(rotationX);
      rotationYSetRef.current!(rotationY);
    };

    const onMouseEnter = (e: MouseEvent<HTMLAnchorElement>) => {
      const rect = e.currentTarget.getBoundingClientRect();
      document.querySelector('.cursor')!.classList.add('is-locked');
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const horizontalToCenter = (x / elementRef.current!.clientWidth) * 2 - 1;
      const verticalToCenter = (y / elementRef.current!.clientHeight) * 2 - 1;

      gsap.set('.cursor-highlight', {
        opacity: 0.3,
        width: rect.height / 2,
        height: rect.height / 2,
        filter: 'blur(76.2385px)',
      });
      tlRef.current = gsap.to('.cursor-content-wrap', {
        opacity: 0,
        duration: 0.2,
      });
      gsap.set('.cursor', {
        width: elementRef.current!.clientWidth,
        height: elementRef.current!.clientHeight,
        top:
          e.clientY - (verticalToCenter * elementRef.current!.clientHeight) / 2,
        left:
          e.clientX -
          (horizontalToCenter * elementRef.current!.clientWidth) / 2,
        x: horizontalToCenter * 3,
        y: verticalToCenter * 0.1 * 1.4,
      });
    };

    const onMouseLeave = () => {
      tlRef.current?.kill();
      document.querySelector('.cursor')!.classList.remove('is-locked');
      gsap.to(elementRef.current, { rotationY: 0, rotationX: 0 });
      gsap.set('.cursor-content-wrap', {
        opacity: 1,
      });
      gsap.set('.cursor-highlight', {
        opacity: 0,
        width: 0,
        height: 0,
        filter: 'blur(0px)',
      });
      gsap.set('.cursor', {
        width: 20,
        height: 20,
      });
    };

    return (
      <FakeLink
        ref={mergeRefs([ref, elementRef]) as any}
        onMouseEnter={windowWidth > 991 ? onMouseEnter : undefined}
        onMouseMove={windowWidth > 991 ? onMouseMove : undefined}
        onMouseLeave={windowWidth > 991 ? onMouseLeave : undefined}
        {...props}
      />
    );
  }),
);

interface Props {
  name: string;
  title: string;
  active: boolean;
  year: string;
}

interface WebProps extends Props {
  url: string;
  startColor: string;
  endColor: string;
  onClick: (item: {
    ref: HTMLAnchorElement;
    name: string;
    url: string;
    title: string;
    year: string;
  }) => void;
}

interface MobileProps extends Props {
  isMobile: true;
  onClick: (item: {
    ref: HTMLAnchorElement;
    name: string;
    title: string;
    year: string;
  }) => void;
}

const ProjectItem = (props: WebProps | MobileProps) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const [isOpening, setIsOpening] = useState(false);

  const { onClick, name, active, ...rest } = props;

  const isMobile = 'isMobile' in props;

  useEffect(() => {
    if (active) {
      setIsOpening(true);
      setTimeout(() => {
        setIsOpening(false);
      }, 300);
    }
  }, [active]);

  const onProjectOpen = () => {
    onClick({ ref: ref.current!, name, ...rest} as any);
  };

  return (
    <li className={cx('project-item', { 'is-mobile': isMobile })}>
      <RotateItem
        ref={ref}
        className={cx({ active: isOpening })}
        onClick={onProjectOpen}
      >
        <div className="project-item-close">
          <PlusIcon />
        </div>
        {!isMobile && (
          <div
            className="item-bg"
            style={{
              background: `linear-gradient(45deg, ${props.startColor} 0%, ${props.endColor} 100%)`,
            }}
          />
        )}
        <div className="img-wrap">
          <div className="img-inner">
            <Image
              path="projects"
              name={name}
              width={1500}
              height={1500}
              aria-label={name}
              keys={isMobile ? [400, 800, 1200, 1500] : undefined}
            />
            {isMobile && (
              <Image
                className="img-copy-crop"
                path="projects"
                name={name}
                width={1500}
                height={1500}
                aria-label={name}
                keys={[400, 800, 1200, 1500]}
              />
            )}
          </div>
        </div>
      </RotateItem>
    </li>
  );
};

export default memo(ProjectItem);
