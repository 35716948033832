import './Process.scss';

import { gsap } from 'gsap';
import { forwardRef, memo, useEffect, useRef } from 'react';

// import Confettis from './components/Confettis';

const gap = 5;

const process = [
  {
    title: '프로젝트 요청',
    description:
      '작업에 대한 이해도를 높일 수 있도록 고객님이 원하시는 디자인, 참고할 수 있는 웹사이트 정보를 준비하시어 프로젝트 요청을 해 주시기 바랍니다.',
  },
  {
    title: '상담',
    description:
      '크몽 플랫폼 내에서 메시지 등을 통해 제품의 방향성 및 목표를 의논하며 궁금하신 점을 해결해 드립니다.',
  },
  {
    title: '프로젝트 제안',
    description:
      '상담을 통하여 고객님께서 원하시는 프로젝트에 대한 이해도를 바탕으로 가격과 소요되는 기한을 제안해 드립니다.',
  },
  {
    title: '작업',
    description:
      '작업에 필요한 정보를 제공받는 즉시 작업에 임하며 작업과정을 투명하고 효과적으로 고객님과 공유합니다.',
  },
  {
    title: '런칭',
    description:
      '작업 완료 후 정식 런칭 이전에 최상의 퀄리티를 유지하기 위하여 2번의 수정 기회를 드립니다. 프로젝트 성격에 따라 웹사이트 호스팅, 개인 앱 스토어를 통한 앱출시 등을 도와 드릴 수 있습니다.',
  },
];

const useAnimateOnJobsFetched = (ref: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const element = ref.current!;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        scrub: true,
        start: 'top top',
        end: 'bottom bottom',
        invalidateOnRefresh: false,
      },
      defaults: {
        ease: 'linear',
      },
    });

    tl.to(element.querySelector('.section-header'), {
      opacity: 0,
      duration: 1,
    });

    for (let i = 0; i < process.length; i++) {
      for (let j = 0; j < i; j++) {
        tl.to(
          `.process-card:nth-child(${j + 1})`,
          {
            scale: 1 - (i - j) * 0.01 * gap,
            duration: 1,
          },
          j === 0 ? '>=1' : '<',
        );
      }

      tl.to(
        `.process-card:nth-child(${i + 1})`,
        {
          opacity: 1,
          duration: 0.5,
        },
        '<',
      );
      tl.to(
        `.process-card:nth-child(${i + 1})`,
        {
          y: `${(130 - i * gap) / 2}%`,
          duration: 1,
        },
        '<',
      );
      tl.to(`.process-card:nth-child(${i + 1})`, {
        y: `${-15 + i * gap}%`,
      });
    }
  }, []);
};

interface ProcessCardProps {
  index: number;
  title: string;
  description: string;
  children?: React.ReactNode;
}

const ProcessCard = memo(
  ({ index, title, description, children }: ProcessCardProps) => {
    return (
      <li className="process-card">
        <div className="step">{index + 1}</div>
        <div className="content">
          <h3 className="process-card-heading">{title}</h3>
          <p className="process-card-description">{description}</p>
        </div>
        {children}
      </li>
    );
  },
);

const Process = forwardRef<HTMLElement>((_, ref) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  useAnimateOnJobsFetched(triggerRef);

  return (
    <section ref={ref} className="process-sec">
      <div ref={triggerRef} className="scroll-wrap">
        <div className="sticky-wrap">
          <div className="section section--process section--light">
            <div className="container">
              <div className="section-header">
                <h2 className="section-heading">
                  소중한 고객님과의 협업 프로세스
                </h2>
              </div>
              <ol>
                {process.map((card, index) => (
                  <ProcessCard key={card.title} index={index} {...card}>
                    {/* {index === process.length - 1 && (
                    <Confettis triggerRef={triggerRef} />
                  )} */}
                  </ProcessCard>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

Process.displayName = 'Process';

export default Process;
