import Card from 'components/Card';
import Performance from 'assets/fast-loading-times.webp';

const BenefitsPerformance = () => {
  return (
    <Card
      className="benefits-performance"
      cardClassName="card--screen-slice cc-right"
    >
      <div className="screen-slice-content cc-right">
        <h3 className="intro-card-heading">빠르고 품격있는 퍼포먼스</h3>
        <p className="intro-card-description intro-card-description--animations">
          빛의 로딩 타임<br/>제 모든 빌드는 퍼포먼스와 효율성을 강조하여 진행됩니다.
        </p>
      </div>
      <img
        src={Performance}
        className="screen-slice-img-right"
        alt="lighthouse"
        width={801}
        height={1005}
      />
    </Card>
  );
};

export default BenefitsPerformance;
