import './Cursor.scss';

import gsap from 'gsap';
import { useEffect } from 'react';

import useSize from 'hooks/useSize';

const Cursor = () => {
  const [windowWidth] = useSize();

  useEffect(() => {
    const cursorLeftSet = gsap.quickSetter('.cursor-highlight', 'left', 'px');
    const cursorTopSet = gsap.quickSetter('.cursor-highlight', 'top', 'px');

    const mousemove = (event: MouseEvent) => {
      gsap.set('.cursor:not(.is-locked)', {
        left: event.clientX,
        top: event.clientY,
      });
      cursorLeftSet(event.clientX);
      cursorTopSet(event.clientY);
    };
    const mousedown = () => {
      gsap.to('.cursor-content-wrap', {
        scale: 0.9,
        duration: 0.1,
      });
      gsap.to('.cursor-content', { opacity: 0.8, duration: 0.1 });
    };
    const mouseup = () => {
      gsap.to('.cursor-content-wrap', {
        scale: 1,
        duration: 0.1,
      });
      gsap.to('.cursor-content', { opacity: 0.5, duration: 0.1 });
    };

    if (windowWidth > 991) {
      document.addEventListener('mousemove', mousemove);
      document.addEventListener('mousedown', mousedown);
      document.addEventListener('mouseup', mouseup);
    } else {
      document.removeEventListener('mousemove', mousemove);
      document.removeEventListener('mousedown', mousedown);
      document.removeEventListener('mouseup', mouseup);
    }
  }, [windowWidth]);

  return (
    <div className="custom-cursor">
      <div className="cursor">
        <div className="cursor-content-wrap">
          <div className="cursor-content"></div>
        </div>
      </div>
      <div className="cursor-highlight"></div>
    </div>
  );
};

export default Cursor;
