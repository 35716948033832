import './BenefitsFast.scss';

import { gsap } from 'gsap/all';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect, useRef } from 'react';
import Card from 'components/Card';

const useAnimateOnEnter = (ref: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current!,
        start: '80% bottom',
      },
    });

    tl.fromTo(
      '.speed-months > div:first-child',
      { width: 0 },
      { width: '110%', duration: 0.3, ease: 'linear' },
      0.2,
    );
    tl.fromTo('.speed-wrap', { y: '25%' }, { y: 0, duration: 0.5 }, '+=0.1');
    tl.fromTo(
      '.speed-months',
      { opacity: 1 },
      { opacity: 0.25, duration: 0.5 },
      '<=',
    );
    tl.fromTo(
      '.speed-weeks',
      { opacity: 0 },
      { opacity: 1, duration: 0.5 },
      '<=',
    );

    ScrollTrigger.create({
      trigger: ref.current,
      start: 'top bottom',
      end: 'top bottom',
      onLeaveBack: () => {
        tl.pause(0);
      },
    });
  }, []);
};

const BenefitsFast = () => {
  const ref = useRef<HTMLDivElement>(null);
  useAnimateOnEnter(ref);

  return (
    <Card ref={ref} className="benefits-fast">
      <h3 className="intro-card-heading">짧은 작업 시간</h3>
      <div className="intro-card-time-wrap">
        <div className="intro-card-time-border" />
        <div className="speed-wrap">
          <div className="speed-months">
            <div></div>
            <div>수 개월</div>
          </div>
          <div className="speed-weeks">몇 주</div>
        </div>
      </div>
    </Card>
  );
};

export default BenefitsFast;
