import './ProjectDetails.scss';

import cx from 'classnames';
import {
  forwardRef,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactModal from 'react-modal';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as AppStore } from 'assets/icons/appstore.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as GooglePlay } from 'assets/icons/googleplay.svg';
import { ReactComponent as PauseIcon } from 'assets/icons/pause.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import FakeLink from 'components/FakeLink';
import Image from 'components/Image';

interface RoundButtonProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const RoundButton = memo(
  ({ className, children, onClick }: RoundButtonProps) => {
    return (
      <FakeLink className={cx('round-btn', className)} onClick={onClick}>
        {children}
      </FakeLink>
    );
  },
);

const ProjectDetailsCarousel = memo(({ name }: { name: string }) => {
  return (
    <Swiper
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loop
      modules={[Autoplay]}
      speed={1000}
      slidesPerView={1.5}
      centeredSlides={true}
      spaceBetween={24}
      breakpoints={{
        768: {
          spaceBetween: 64,
        },
      }}
    >
      {[1, 2, 3, 4].map((i) => (
        <SwiperSlide key={i}>
          <Image
            path="projects-details"
            name={`${name}_${i}`}
            width={1600}
            height={1186}
            keys={[400, 800, 1200, 1600]}
          />
        </SwiperSlide>
      ))}
      {[1, 2, 3, 4].map((i) => (
        <SwiperSlide key={i}>
          <Image
            path="projects-details"
            name={`${name}_${i}`}
            width={1600}
            height={1186}
            keys={[400, 800, 1200, 1600]}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
});

interface Props {
  onReject: () => void;
  isOpen: boolean;
  name?: string;
  title?: string;
  year?: string;
  keywords?: string;
}

interface WebProps extends Props {
  url?: string;
  startColor?: string;
  endColor?: string;
}

interface MobileProps extends Props {
  isMobile: true;
  appleUrl?: string;
  googleUrl?: string;
}

const ProjectDetails = forwardRef<HTMLDivElement, WebProps | MobileProps>(
  (props, ref) => {
    const { onReject, isOpen, name, title, year, keywords } = props;
    const isMobile = 'isMobile' in props;

    const videoRef = useRef<HTMLVideoElement>(null);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [videoIsAvailable, setVideoIsAvailable] = useState(false);

    useEffect(() => {
      if (isOpen) {
        setTimeout(() => setIsInit(true), 800);
      } else {
        setIsInit(false);
      }
    }, [isOpen]);

    useEffect(() => {
      const escFunction = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          closeModal();
        }
      };
      document.addEventListener('keydown', escFunction, false);

      return () => {
        document.removeEventListener('keydown', escFunction, false);
      };
    }, []);

    useEffect(() => {
      if (!name) {
        return;
      }
      import(`../../assets/projects-details/${name}.mp4`)
        .then(() => setVideoIsAvailable(true))
        .catch((e) => {});

      return () => {
        setVideoIsAvailable(false);
      };
    }, [name]);

    useEffect(() => {
      if (isInit && videoLoaded) {
        const play = async () => {
          try {
            await videoRef.current?.play();
            setVideoPlaying(true);
          } catch (e) {
            console.error(e);
          }
        };

        play();
      }
    }, [isInit, videoLoaded]);

    const togglePlay = useCallback(async () => {
      if (videoPlaying) {
        videoRef.current?.pause();
        setVideoPlaying(false);
      } else {
        try {
          await videoRef.current?.play();
          setVideoPlaying(true);
        } catch (e) {
          console.error(e);
        }
      }
    }, [videoPlaying]);

    const onVideoLoaded = useCallback(() => {
      setVideoLoaded(true);
    }, []);

    const closeModal = useCallback(() => {
      setVideoLoaded(false);
      onReject();
    }, [onReject]);

    return (
      <ReactModal
        isOpen={isOpen}
        closeTimeoutMS={500}
        overlayClassName="project-details-overlay"
        className="project-details"
        overlayRef={(node) =>
          ((ref as MutableRefObject<HTMLDivElement>).current = node)
        }
        overlayElement={(props, contentElement) => (
          <div {...props}>
            <div className="overlay-inner">{contentElement}</div>
          </div>
        )}
        onRequestClose={closeModal}
      >
        {!!name && (
          <>
            <RoundButton className="close-btn" onClick={closeModal}>
              <CloseIcon />
            </RoundButton>
            <div
              className={cx('project-details-header', { active: videoLoaded })}
            >
              <Image
                path="projects"
                name={name}
                width={1600}
                height={925}
                keys={isMobile ? [400, 800, 1200, 1500] : undefined}
                className="project-details-img-preview"
              />
              {videoIsAvailable && (
                <video
                  ref={videoRef}
                  src={
                    new URL(
                      `../../assets/projects-details/${name}.mp4`,
                      import.meta.url,
                    ).href
                  }
                  muted
                  onLoadedData={onVideoLoaded}
                  autoPlay={false}
                  playsInline
                  loop
                ></video>
              )}
              {videoLoaded && isInit && (
                <RoundButton className="play-btn" onClick={togglePlay}>
                  {videoPlaying ? <PauseIcon /> : <PlayIcon />}
                </RoundButton>
              )}
            </div>
            <div className="project-item-info-wrap">
              <div className="project-info-header">
                <h4 className="project-title">{title}</h4>
              </div>
              <div className="mobile-preview">
                <Image
                  path="projects-details"
                  name={`${name}-mobile`}
                  width={isMobile ? 1000 : 1200}
                  height={isMobile ? 1000 : 1200}
                  keys={isMobile ? [400, 800, 1200] : [500, 800, 1000]}
                />
              </div>
              {!isMobile ? (
                <div className="project-info-cta-wrap">
                  <a
                    className="open-btn"
                    href={props.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    사이트 바로가기
                  </a>
                </div>
              ) : (
                (props.appleUrl || props.googleUrl) && (
                  <div className="project-info-cta-wrap">
                    {props.appleUrl && (
                      <a
                        href={props.appleUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AppStore />
                      </a>
                    )}
                    {props.googleUrl && (
                      <a
                        href={props.googleUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <GooglePlay />
                      </a>
                    )}
                  </div>
                )
              )}
              {!isMobile && <ProjectDetailsCarousel name={name} />}
              <div className="project-info-bottom">
                <div className="project-info-bottom-left">
                  <div className="project-info-title-wrap">
                    <div className="project-info-bottom-title">{title}</div>
                    <div className="project-info-year">{year}</div>
                  </div>
                  <div>{keywords}</div>
                </div>
                <div>
                  {!isMobile && (
                    <a
                      className="project-cta"
                      href={props.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      사이트 바로가기
                    </a>
                  )}
                  <div className="project-info-close">
                    <span>닫기 위해 </span>
                    <span className="project-info-esc">esc</span>
                    <span>를 누르세요.</span>
                  </div>
                  <FakeLink
                    className="project-info-close-mobile"
                    onClick={closeModal}
                  >
                    닫기
                  </FakeLink>
                </div>
              </div>
            </div>
          </>
        )}
      </ReactModal>
    );
  },
);

ProjectDetails.displayName = 'ProjectDetails';

export default ProjectDetails;
