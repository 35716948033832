import './BenefitsCleanCode.scss';

import CleanCode from 'assets/code-snippet.webp';
import Card from 'components/Card';

const BenefitsCleanCode = () => {
  return (
    <Card className="benefits-clean-code">
      <h3 className="intro-card-heading">깔끔한 코드</h3>
      <div className="intro-card-code-wrap">
        <img src={CleanCode} alt="" width={1590} height={645} />
      </div>
    </Card>
  );
};

export default BenefitsCleanCode;
